import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import Home from '../Screens/LandingPage/Home';
import AboutUs from '../Screens/AboutUs/AboutUs';
import Industries from '../Screens/Industries/Industries';
import ContactUs from '../Screens/ContactUs/ContactUs';
import RefillStation from '../Screens/RefillStation/RefillStation';
import PrivacyPolicy from '../Screens/policy/PrivacyPolicy';
import TermsAndConditions from '../Screens/policy/TermsAndConditions';

import Layout from '../Layout/Layout';
class InnerRoutes extends React.Component {
    render() {
        const {
            match: { url },
        } = this.props;
        return (
            <Layout {...this.props}>
                <Switch>
                    <Route exact path={`/`} component={Home} />
                    <Route exact path={`/about-us`} component={AboutUs} />
                    <Route exact path={`/industries`} component={Industries} />
                    <Route exact path={`/contact`} component={ContactUs} />
                    <Route exact path={`/refillStation`} component={RefillStation} />
                    <Route exact path={`/privacypolicy`} component={PrivacyPolicy} />
                    <Route exact path={`/termsandconditions`} component={TermsAndConditions} />
                </Switch>
            </Layout>
        );
    }
}

export default InnerRoutes;
