import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./HomeSlider.scss";
import { Row, Col } from "antd";

const Mainslidersettings = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

class HomeSlider extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <!--Main Slider section starts--> */}
        <div className="quikjets-banner">
          <div className="quikjets-banner-center">
            <div className="slider-content">
              {/* <!--Slider Item starts--> */}
              <Slider {...Mainslidersettings}>
                <div className="item empty-legs-flight">
                  <Row justify="left" align="left">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h3>Better for you and better for the plant</h3>
                            <h2>Rehyderate . Refill . Repeat</h2>
                            <p>
                              We’re revolutionising the way we drink water
                              through a network of handy high-tech refill
                              stations that provide fresh chilled natural spring
                              water, located in the places you’ll need it most.
                            </p>

                            <div className="action-buttons">
                              <Link to="/contact" className="btn btn-standard">
                                Contact Us
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item world-class-service">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h3>We are in different sectors</h3>
                            <h2>
                              Bottleless Water Dispenser for all Industries
                            </h2>
                            <p>
                              Anytime Water is the best-tasting, healthiest
                              water on the planet, for a fraction of the cost of
                              bottled water, and 100% less plastic waste. our
                              Refill Station technology improves how businesses
                              hydrate their employees.
                            </p>

                            <div className="action-buttons">
                              <Link to="/contact" className="btn btn-standard">
                                Contact Us
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item memership">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                           <h3>Reduces single use plastic.</h3>
                            <h2>Water refilling stations designed to reduce plastics</h2>
                            <p>
                            Disposable plastic bottles damage our environment. We offer a customised alternative that can help.
                            </p>

                            <div className="action-buttons">
                              <Link to="/contact" className="btn btn-standard">
                                Contact Us
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
              </Slider>
            </div>
          </div>
        </div>
        {/* <!--Main Slider section ends--> */}
      </React.Fragment>
    );
  }
}
export default withRouter(HomeSlider);
