import React from "react";
import { Row, Col } from "antd";
import Slider from "react-slick";
import "./Areas.scss";

const Mainslidersettings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 5000,
};

const Areas = () => {
  return (
    <Slider {...Mainslidersettings}>
      <div>
        <Row justify="Center" className="Bussines">
          <Col span={5}>
            <div className="content">
              <div className="center-content">
                <div className="slider-caption">
                  <h2>Bussines</h2>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* <!--Slider Item ends--> */}

      <div>
        <Row justify="center" className="Residential">
          <Col span={5}>
            <h1>Residential</h1>
          </Col>
        </Row>
      </div>
      {/* <!--Slider Item ends--> */}

      <div>
        <Row justify="center" className="Commercial">
          <Col span={5}>
            <h2>Commercial</h2>
          </Col>
        </Row>
      </div>
      {/* <!--Slider Item ends--> */}

      <div>
        <Row className="Retail">
          <Col span={5}>
            <h2>Retail</h2>
          </Col>
        </Row>
      </div>
      {/* <!--Slider Item ends--> */}

      <div>
        <Row justify="center" className="Fitness">
          <Col span={5}>
            <h2>Fitness</h2>
          </Col>
        </Row>
      </div>
      {/* <!--Slider Item ends--> */}
      <div>
        <Row className="School">
          <Col span={5}>
            <h2>School</h2>
          </Col>
        </Row>
      </div>
      {/* <!--Slider Item ends--> */}
      <div>
        <Row justify="center" className="CoWorker">
          <Col span={5}>
            <h2>Co-Worker</h2>
          </Col>
        </Row>
      </div>
      {/* <!--Slider Item ends--> */}
    </Slider>
  );
};

export default Areas;
