import React from "react";
import "./WhyMore.scss";
import mainlogo from "../../../assets/images/anytime-water.png";
import { Row, Col } from 'antd';

const WhyMorethanWater = () => {
  return (
    <div className="Why">
      <div className="logo">
        <img src={mainlogo} alt="Staging Depot" />{" "}
      </div>
      <div className="title">
        <h1>Why more than water</h1>
      </div>
      <div className="container mx-auto">
        <Row align="center" className="row">
          <Col 
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 6 }}
         className="coloumn">
            <div className="icon flaticon-water-1">
              <img
                src={require("../../../assets/images/feature-icons/water-drop.png")}
                alt=""
              />
              <h3 >Fresh and healthy natural spring water</h3>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 6 }}
            className="coloumn">
            <div className="icon flaticon-water-1">
              <img
                src={require("../../../assets/images/feature-icons/cold.png")}
                alt=""
              />
              <h3>Still or sparkling</h3>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 6 }}
            className="coloumn" >
            <div className="custom-icon">
              <img
                src={require("../../../assets/images/feature-icons/ice.png")}
                alt=""
              />
              <h3>Chilled to perfection</h3>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 6 }}
            className="coloumn">
            <div className="custom-icon">
              <img
                src={require("../../../assets/images/feature-icons/water.png")}
                alt=""
              />
              <h3>Refill with your own bottle, or ours</h3>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default WhyMorethanWater;
