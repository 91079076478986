import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { Row, Col, Divider } from "antd";
import "./Footer.scss";
import mainlogo from "../../src/assets/images/anytime-water.png";

export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="container mx-auto">
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <div className="about-company">
                <div className="logo">
                  <img src={mainlogo} alt="Staging Depot" />{" "}
                </div>
                <div className="title">
                  <p>
                    We are a award winning multinational company. We believe in
                    quality and standard worldwide.
                  </p>
                </div>
              </div>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <div className="quick-links">
                <h3>UseFul Link</h3>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link>Our Team</Link>
                  </li>
                  <li>
                    <Link>Our Blog</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <div className="social-media quick-links">
                <h3>Follow Us</h3>
                <ul>
                  <li>
                    <Link>
                      <i className="flaticon-facebook-3" />
                      -facebook
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <i className="flaticon-instagram" /> -instagram
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <i className="flaticon-twitter" /> - twitter
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <i className="flaticon-youtube-1" /> -youtube
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <div className="quick-links">
                <h3>Quick Links</h3>
                <ul>
                  <li>
                    <Link>Careers</Link>
                  </li>
                  <li>
                    <Link to="/termsandconditions">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacypolicy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link>Copyright Policy</Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
        <div align="center" style={{ paddingTop: "40px" }}>
          <p>Copyright © 2022 atwquench. All Rights Reserved.</p>
        </div>
      </div>
    );
  }
}
