import React from "react";
import { withRouter } from "react-router";
import { Row, Col } from "antd";
import mainlogo from "../../assets/images/main-slider/bg1.jpg";

class RefillStation extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="page-banners bundles">
          <div className="container mx-auto">
            <h1 style={{ fontSize: "45px" }}>Re-fill Station</h1>
            <p>
              We’re revolutionising the way we drink water through a network of
              handy high-tech refill stations that provide fresh chilled natural
              spring water, located in the places you’ll need it most.
            </p>
          </div>
        </section>
        <div className="inner-pages padding-40 top-left-wave light-bg">
          <div className="container mx-auto">
            <section id="about">
              <div>
                <Row
                  gutter={[
                    { xs: 8, sm: 20, md: 30, lg: 40 },
                    { xs: 8, sm: 16, md: 10, lg: 10 },
                  ]}
                >
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 16 }}
                    lg={{ span: 12 }}
                  >
                    <p style={{ color: "blue" }}>Re-Fill Station</p>
                    <h1 style={{ fontSize: "40px" }}>Re-Fill Station</h1>
                    <h3>Rehyderate . Refill . Repeat</h3>
                    <p style={{ fontSize: "20px", color: "#807a7a" }}>
                      We’re revolutionising the way we drink water through a
                      network of handy high-tech refill stations that provide
                      fresh chilled natural spring water, located in the places
                      you’ll need it most.
                    </p>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 12 }}
                  >
                    <img src={mainlogo} alt="Refill Station" />
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(RefillStation);
