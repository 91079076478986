import React from "react";
import './CustomizeLife.scss';

const CustomizeLifeStyle = () => {
  return (
    <div className="customizeLife">
      <div>
        <h1>Customize for your lifestyle</h1>
      </div>
      <div>
        <p>
          We want to inspire healthier lives through our better, safer water.
          That means all kinds of lives—busy,<br></br> relaxed or somewhere in between.
          Whether you’re a traveler, a homebody, <br></br>a parent or a pet, Primo has a
          dispenser that’s just for you.
        </p>
      </div>
    </div>
  );
};

export default CustomizeLifeStyle;
