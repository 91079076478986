import React from "react";
import "./Home.scss";
import HomeSlider from "./HomeSlider/HomeSlider";
import TopFeature from "./HomeTopFeature/TopFeature";
import WhyMorethanWater from "./WhyMoreThanWater/WhyMorethanWater";
import { Row, Col } from "antd";
import CustomizeLifeStyle from "./CustomizeLifeStyle/CustomizeLifeStyle";
import BenifitsOfWater from "./HomeTopFeature/BenifitsOfWater";
import Areas from "./Areas/Areas";
import mainlogo from "../../assets/images/about.jpg"


export default class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* main slider starts */}
        <HomeSlider />
        {/* main slider ends */}

        {/* Top Features */}
        <TopFeature />

        {/* Why More than Water */}
        <WhyMorethanWater />

        {/* About Us  */}
        <section id="about">
          <div>
            <Row
              gutter={[
                { xs: 8, sm: 20, md: 30, lg: 40 },
                { xs: 8, sm: 16, md: 10, lg: 10 },
              ]}
            >
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 16 }}
                lg={{ span: 12 }}
              >
                <p style={{color:"blue"}}>About Us</p>
                <h1 style={{fontSize:"40px"}}>Who we are</h1>
                <p>
                  Anytime Water Quench is the only MNC which offers bottleless
                  water. Our water is pristine, clean and certified. Our
                  customers can refill through our waterstations installed
                  worldwide.
                </p>
                <p>
                  ATW Quench is determined to make the world plastic free and
                  would encourage our customers to help us in this mission and
                  not to encourage carrying plastic bottles for drinking,
                  instead carry their own copper infused jars which are highly
                  recommend for healthy drinking.
                </p>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 12 }}
              >
                <img src={mainlogo} alt="Staging Depot" />
              </Col>
            </Row>
          </div>
        </section>


        {/* Customize for your lifestyle */}
           <CustomizeLifeStyle />

           {/* Benifits-of-water */}
           <BenifitsOfWater />

           {/* Area */}
           <Areas />

      </React.Fragment>
    );
  }
}
