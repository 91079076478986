import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { Row, Button, Col, Tooltip } from "antd";
import mainlogo from "../assets/images/atw-logo-dark.png";
import ModalBox from "../Components/ModalBox/ModalBox";
import SigninSignup from "../Screens/SigninSignup/SigninSignup";
import { SearchOutlined } from "@ant-design/icons";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import africa from "../assets/images/country/africa.png"
import Australia from "../assets/images/country/Australia.png"
import Dubai from "../assets/images/country/Dubai.png"
import india from "../assets/images/country/india.png"
import mexico from "../assets/images/country/mexico.png"
import Russia from "../assets/images/country/Russia.png"
import uk from "../assets/images/country/uk.png"
import usa from "../assets/images/country/usa.png"

const menu = (
  <Menu>
    <Menu.Item key="0">
      <a target="" rel="#"  href ><img src={india} style={{width:"auto", height:"auto"}} alt="india"/>
        - india
      </a> 
    </Menu.Item>
    <Menu.Item key="1">
      <a target="" rel="#" href="/"><img src={usa} style={{width:"auto", height:"auto"}} alt="usa"/>
       - America
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="" rel="#" href="/"><img src={africa} style={{width:"auto", height:"auto"}} alt="africa"/>
       - Africa
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="" rel="#" href="/"><img src={Australia} style={{width:"auto", height:"auto"}} alt="australia"/>
       - Australia
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="" rel="#" href="/"><img src={Russia} style={{width:"auto", height:"auto"}} alt="russia"/>
       - Russia
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="" rel="#" href="/"><img src={Dubai} style={{width:"auto", height:"auto"}} alt="dubai"/>
       - Dubai
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="" rel="#" href><img src={mexico} style={{width:"auto", height:"auto"}} alt="mexico"/>
      - Mexico
      </a>
    </Menu.Item>
    <Menu.Item key="1">
      <a target="" rel="#" href><img src={uk} style={{width:"auto", height:"auto"}} alt="UK"/>
       - UK
      </a>
    </Menu.Item>
  </Menu>
);

export let setHeader = false;
export default class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      signinsignup: false,
      headerView: false,
      name: "",
    };
  }


  onCancel = () => {
    this.setState({ open: false });
    this.setState({ signinsignup: false });
  };
  render() {
    console.log(this.props);
    return (
      <React.Fragment>
        <div className="cta-header">
          <div className="container mx-auto">
            <Row align="center">
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}
                align="left"
              >
                <div className="book-jets">
                  <a href="tel:+91 90352 26890">
                    <i className="flaticon-phone-call" />
                    +91 90352 26890
                  </a>
                  <span>or</span>
                  <a href="mailto:info@atwquench.com">
                    <i className="flaticon-email-1" /> info@atwquench.com
                  </a>
                </div>
              </Col>
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}
                align="right"
              >
                <div className="social-media">
                  <ul>
                    <li>
                      <Link>
                        <i className="flaticon-facebook-3" />
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="flaticon-instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="flaticon-twitter" />
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="flaticon-youtube-1" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="main-hearder">
          <div className="container mx-auto">
            <div className="top-header">
              <Row>
                <Col
                  xs={{ span: 24, order: 1 }}
                  sm={{ span: 24, order: 1 }}
                  md={{ span: 4, order: 1 }}
                  lg={{ span: 4, order: 1 }}
                >
                  <div className="left-section">
                    <div className="logo">
                      <Link to="/">
                        <img src={mainlogo} alt="logo" />{" "}
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24, order: 2 }}
                  sm={{ span: 24, order: 2 }}
                  md={{ span: 17, order: 2 }}
                  lg={{ span: 17, order: 2 }}
                >
                  <div className="bottom-header">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About</Link>
                      </li>
                      <li>
                        <Link to="/refillStation">Refill Station</Link>
                      </li>
                      <li>
                        <Link to="/industries">Industries</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                      <li className="dropdown">
                        <Dropdown overlay={menu}>
                          <a
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}
                          href>
                            Countries <DownOutlined />
                          </a>
                        </Dropdown>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24, order: 3 }}
                  sm={{ span: 24, order: 3 }}
                  md={{ span: 3, order: 3 }}
                  lg={{ span: 3, order: 3 }}
                  align="right"
                >
                  <Tooltip title="search">
                    <Button style={{backgroundColor:"#0f2765", color:"#ffff", marginTop:"15px"}}
                      className="search-button"
                      shape="circle"
                      icon={<SearchOutlined />}
                      size="large"
                      
                    />
                  </Tooltip>
                </Col>
              </Row>
            </div>

            {/* bottom header starts */}

            {/* bottom header ends */}
          </div>
        </div>

        <ModalBox
          content="test"
          visible={!!this.state.signinsignup}
          width={900}
          onCancel={this.onCancel}
          footer={null}
          destroyOnClose
        >
          <SigninSignup />
        </ModalBox>
      </React.Fragment>
    );
  }
}
