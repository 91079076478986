import React from "react";
import { Row, Col } from "antd";
import './BenifitsOfWater.scss';

const BenifitsOfWater = () => {
  return (
    <div>
      <React.Fragment>
        <section className="Benifits-of-water">
        <h1>Benefits of Water Stations</h1>
          <div className="container mx-auto">
            <Row align="center" className="row">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                className="coloumn"
              >
                <div className="icon flaticon-water-1">
                  <img
                    src={require("../../../assets/images/feature-icons/1.png")}
                    alt=""
                  />
                  <h3>Sustainability</h3>
                  <p>
                    Our portable water refill stations reduces a festival or
                    event’s overall carbon footprint by eliminating the need for
                    single-use plastic water bottles.
                  </p>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                className="coloumn"
              >
                <div className="icon flaticon-water-1">
                  <img
                    src={require("../../../assets/images/feature-icons/water.png")}
                    alt=""
                  />
                  <h3>Health & Safety</h3>
                  <p>
                    Our water treatment solutions keep festival attendees and
                    staff hydrated, while decreasing the risk of heatstroke and
                    other heat related ailments.
                  </p>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                className="coloumn"
              >
                <div className="custom-icon">
                  <img
                    src={require("../../../assets/images/feature-icons/fountain.png")}
                    alt=""
                  />
                  <h3>Fan Experience</h3>
                  <p>
                    Offering the access to free, cold, filtered water is one of
                    the best ways to give back to your festivalgoers, and their
                    appreciation of free water is regularly broadcast on social
                    media sites and onsite.
                  </p>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                className="coloumn"
              >
                <div className="custom-icon">
                  <img
                    src={require("../../../assets/images/feature-icons/2.png")}
                    alt=""
                  />
                  <h3>Revenue Generating</h3>
                  <p>
                    Whether through sponsorship or reusable bottle sales, there
                    are multiple ways to generate revenue with water stations
                    and offering free water
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    </div>
  );
};

export default BenifitsOfWater;
