import React from "react";
import { withRouter } from "react-router";
import { Row, Col } from "antd";
import "./AboutUs.scss";
// import QuickLinks from "../QuickLinks/QuickLinks";
import mainlogo from "../../assets/images/about.jpg";

class AboutUs extends React.Component {
  render() {
    return (
      <React.Fragment>
      <section className="page-banners bundles">
          <div className="container mx-auto" >
            <h1 style={{fontSize:"55px"}}>About Us</h1>
            <p>
            Our water is pristine, clean and
                      certified. Our customers can refill through our
                      waterstations installed worldwide.st of
                              bottled water, and 100% less plastic waste.
            </p>
          </div>
        </section>
        {/* page banners */}
        {/* <section className="about-banners">
          <img
            src={require("../../assets/images/page-banners/about.jpg")}
            alt="About Us"
          />
        </section> */}
        {/* page banners */}

        <div className="inner-pages padding-40 top-left-wave light-bg">
          <div className="container mx-auto">
            <section id="about">
              <div>
                <Row
                  gutter={[
                    { xs: 8, sm: 20, md: 30, lg: 40 },
                    { xs: 8, sm: 16, md: 10, lg: 10 },
                  ]}
                >
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 16 }}
                    lg={{ span: 12 }}
                  >
                    <p style={{ color: "blue" }}>About Us</p>
                    <h1 style={{ fontSize: "40px" }}>Who we are</h1>
                    <p>
                      Anytime Water Quench is the only MNC which offers
                      bottleless water. Our water is pristine, clean and
                      certified. Our customers can refill through our
                      waterstations installed worldwide.
                    </p>
                    <p>
                      ATW Quench is determined to make the world plastic free
                      and would encourage our customers to help us in this
                      mission and not to encourage carrying plastic bottles for
                      drinking, instead carry their own copper infused jars
                      which are highly recommend for healthy drinking.
                    </p>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 12 }}
                  >
                    <img src={mainlogo} alt="Staging Depot" />
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(AboutUs);
