import React from 'react'
import { withRouter } from 'react-router';
import { Row, Col } from 'antd';

const TopFeature = () => {
    return (
        <React.Fragment>
                 <section className="staging-depot-comitment" style={{padding:"90px 0px"}}>
                    <div className="container mx-auto">
                        <Row align="center" style={{textAlign:"center"}}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 7 }}>
                                <div className="icon flaticon-water-1">
                                    <img src={require("../../../assets/images/feature-icons/1.png")} alt="" />
                                    <h3>Safe Water</h3>
                                    <p>Consumers desire easier, greener, economic access for their refillable bottles, We want to inspire healthier lives through our better, safer water.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 7 }}>
                                <div className="custom-icon">
                                    <img src={require("../../../assets/images/feature-icons/water.png")} alt="" />
                                    <h3>Less Waste</h3>
                                    <p>we create water that tastes and hydrates better than anything in a single-use bottle, delivered into any refillable container.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 7 }}>
                                <div className="custom-icon">
                                    <img src={require("../../../assets/images/feature-icons/healthy-life.png")} alt="" />
                                    <h3>Healthier Habits</h3>
                                    <p>Join us as we work to inspire healthier lives through reducing plastic waste and providing safer water* for all.</p>
                                </div>
                            </Col>
                        </Row>
                        {/* icons end */}
                    </div>
                </section>
            </React.Fragment>
    )
}

export default withRouter(TopFeature)
